html, body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  background: #F5F5F5;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}


#left {
  /* display: flexbox; */
  /* position: absolute; */
  /* flex-direction: column; */
  /* justify-content: center; */
  width: 50%;
  height: 100%;
}

#right {
  position: relative;
  width: 50%;
  height: 600px;
  background-color: #eee;
  -webkit-touch-callout: none;
}

body {
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none;
}

.front {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  background-color: rgb(255, 255, 255);
}

.box {
  display: flex;
  width: 100%;
  background-color: #ccc;
  -webkit-touch-callout: none;
}

.back {
  pointer-events: none;
  background-image: url('../assets/UI/JoyStick/j.png');
  background-size: cover;
  /* transform: scale(0.5); */
  -webkit-touch-callout: none;
  background-color: rgb(216, 216, 216);
}

.wrap {
  display: flex;
  width: 100vw;
  flex-grow: 1;
  position: relative;
}

.dropzone {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.placeholder {
  width: 100%;
  max-width: 500px;
  border-radius: 0.5em;
  background: #EEE;
  padding: 2em;
  text-align: center;
}

.placeholder p {
  font-size: 1.2rem;
  color: #999;
}

.viewer {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  position: absolute;
  top: 0;
}

.demo-wrapper {
    width:50%;
	  padding: 0em;
    text-align: center;
    position: absolute;
    left: 25%;
    top: 50px;
    font-family: '';
}
 .html5-progress-bar {
	padding: 1px 1px;
	border-radius: 3px;
	background-color: #fff;
	box-shadow:  0px 1px 2px 0px rgba(0, 0, 0, .2);
}
.html5-progress-bar progress {
	background-color: #f3f3f3;
	border: 0;
	width: 80%;
	height: 18px;
	border-radius: 9px;
}
 .html5-progress-bar progress::-webkit-progress-bar {
	background-color: #f3f3f3;
	border-radius: 9px;
}
.html5-progress-bar progress::-webkit-progress-value {
	background: #cdeb8e;
	background: -moz-linear-gradient(top,  #cdeb8e 0%, #a5c956 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#cdeb8e), color-stop(100%,#a5c956));
	background: -webkit-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
	background: -o-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
	background: -ms-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
	background: linear-gradient(to bottom,  #cdeb8e 0%,#a5c956 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cdeb8e', endColorstr='#a5c956',GradientType=0 );
	border-radius: 9px;
}
.html5-progress-bar progress::-moz-progress-bar {
	background: #cdeb8e;
	background: -moz-linear-gradient(top,  #cdeb8e 0%, #a5c956 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#cdeb8e), color-stop(100%,#a5c956));
	background: -webkit-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
	background: -o-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
	background: -ms-linear-gradient(top,  #cdeb8e 0%,#a5c956 100%);
	background: linear-gradient(to bottom,  #cdeb8e 0%,#a5c956 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cdeb8e', endColorstr='#a5c956',GradientType=0 );
	border-radius: 9px;
}
wrap .html5-progress-bar .progress-value {
	padding: 0px 5px;
	line-height: 20px;
	margin-left: 5px;
	font-size: .8em;
	color: #555;
	height: 18px;
	float: right;
}

.info-performance {
    width:90%;
    text-align: center;
    position: absolute;
    left: 5%;
    top: 5px;
    font-family: '';
}

.info-performance .info-span{
	text-align: center;
    font-weight: 500;
    font-family: '';
}

.info-performance .info-span-title {
    text-align: center;
    font-weight: 600;
    font-family: '';
}
/******************************************************************************
 * Header
 */

header {
  display: flex;
  background: #353535;
  padding: 0 2em;
  height: 4rem;
  line-height: 4rem;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.3);
  z-index: 1;

  -webkit-app-region: drag;
}

header h1,
header .item,
header .separator {
  color: #F5F5F5;
  font-weight: 300;
  line-height: 4rem;
  margin: 0;
}

header h1 {
  font-size: 1.4rem;
}

header h1 > a {
  color: inherit;
  font-size: inherit;
  text-decoration: inherit;
}

header .item {
  padding: 0 1em;
  font-size: 0.8rem;
  text-decoration: none;
  transition: background ease 0.2s;

  -webkit-app-region: no-drag;
}

header .item:hover {
  background: #444;
}

header button.item {
  height: 34px;
  line-height: 35px;
  padding: 0 1em;
  border: 0;
  background: #ffc107;
  color: #333;
  font-weight: 500;
  border-radius: 2px;
  cursor: pointer;
}

header button.item:hover {
  color: #000;
}

header .separator {
  margin: 0 0.2em;
  opacity: 0.2;
}

header h1 + .separator {
  margin-left: 1em;
}

.flex-grow {
  flex-grow: 1;
}

.gui-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  pointer-events: none;
}

.gui-wrap > .main {
  pointer-events: all;
}

.dg li.gui-stats:not(.folder) {
  height: auto;
}

@media screen and (max-width: 700px) {
  header h1 {
    font-size: 1em;
  }

  .layout-md {
    display: none;
  }
}

/******************************************************************************
 * Upload Button
 *
 * https://tympanus.net/Tutorials/CustomFileInputs/
 */

.upload-btn {
  margin-top: 2em;
}

.upload-btn input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload-btn label {
  color: #353535;
  border: 0;
  border-radius: 3px;
  transition: ease 0.2s background;
  font-size: 1rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
}

.upload-btn label:hover {
  background: #DDD;
}

.upload-btn svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}


/******************************************************************************
 * Validation report
 */

.report {
  padding: 2em;
  max-width: 860px;
}

.report h1 {
  margin-top: 0;
}

.report p,
.report ul {
  line-height: 1.5em;
}

.report-table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
}

.report-table thead tr {
  background: #404040;
  color: #FFF;
}

.report-table th,
.report-table td {
  padding: 0.5em 1em;
}

.report-table tr:nth-child(2n) {
  background: #F0F0F0;
}

/******************************************************************************
 * Validation toggle
 */

.report-toggle-wrap.hidden { display: none; }

.report-toggle {
  cursor: pointer;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 20px;
  height: 30px;
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.25);
  background: #FFF;
  box-sizing: border-box;

  color: #f0f0f0;
  background: #000;
  border-left: 6px solid #000;
}

.report-toggle.level-1 { color: #444; background: #ffeda0; border-left-color: #feb24c; }
.report-toggle.level-0 { color: #444; background: #f4c2be; border-left-color: #b10026; }

.report-toggle-text {
  line-height: 32px;
  padding: 0 0.5em;
  font-weight: 300;
  font-size: 0.8em;
}

.report-toggle-close {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 1.5em;
  text-align: center;
}

/******************************************************************************
 * CSS Spinner
 *
 * http://tobiasahlin.com/spinkit/
 */

.spinner {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px;

  background-color: #333;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

#fade {
  display: none;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1001;
  -moz-opacity: 0.8;
  opacity: .80;
  filter: alpha(opacity=80);
}

#light {
  display: none; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0px solid #FFF;
  background: #FFF;
  z-index: 1002; 
  overflow: visible;
}
#mainlight {
  display: none;
  position: absolute;
  /* top: 50%;
  left: 50%;
  max-width: 800px;
  max-height: 450px;
  margin-left: -400px;
  margin-top: -225px; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0px solid #FFF;
  background: #FFF;
  z-index: 1002;
 
  overflow: visible;
}
.Image {

  display: none;
  position:relative;
  max-width: 100%;
  max-height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  z-index: 1002;
  /* background-image: url("../assets/textures/11.png"); */
     
}
.pictureclose {
  /* position: absolute; */
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  /* float: right; */
/*  
    max-width: 20%;
    max-height: 20%;
    top: 10%;
    right: 0px; */


}
.box{

  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 800px;
  height: 450px;
  border: 0px solid #FFF;
  /* background-image: url("../assets/textures/11.png"); */
  z-index: 1002;
  overflow: visible;

}
.bottom-btns-bottom{
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 1;
  display: none;
  pointer-events: none;
}
#bottomIcon {
  bottom: 10px;
  right: 20px;
  position: absolute;
  width: 120px;
  height: 35px;
  background-image: url("../assets/textures/tv/fanhuizhongguoguan.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size:  120px 35px;
  cursor: pointer;
  z-index: 0; 
  pointer-events:all;
}
#dianzan {
  bottom: 10px;
  left: 20px;
  position: absolute;
  width: 120px;
  height: 35px;
  background-image: url("../assets/textures/tv/dianzan.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size:  120px 35px;
  cursor: pointer;
  z-index: 0; 
  pointer-events:all;
}
#xiaoyouxi {
  top: 10px;
  right: 20px;
  position: absolute;
  width: 120px;
  height: 35px;
  background-image: url("../assets/textures/tv/xiaoyouxi.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size:  120px 35px;
  cursor: pointer;
  z-index: 0; 
  pointer-events:all;
}

.bottom-btns-top {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 1;
  display: none;
  pointer-events: none;
}

#search {
  top: 10px;
  right: 70px;
  position: absolute;
  width: 90px;
  height: 35px;
  background-image: url("../assets/textures/tv/canzhanpingpaianniu.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 90px 35px;
  cursor: pointer;
  z-index: 0; 
  pointer-events:all;
}

#backToTV  {  
  top: 10px;
  right: 0;
  position: absolute;
  width: 70px;
  height: 35px;
  background-image: url("../assets/textures/tv/fanhui.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70px 35px;
  cursor: pointer;
  z-index: 0; 
  pointer-events:all;
}
.bottom-btns-top-ex {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 1;
  display: none;
  pointer-events: none;
}
#shuoming{
  top: 10px;
  right: 70px;
  position: absolute;
  width: 70px;
  height: 35px;
  background-image: url("../assets/textures/tv/shuoming.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70px 35px;
  cursor: pointer;
  z-index: 0; 
  pointer-events:all;

}
#BackToMainScene{
  top: 10px;
  right: 0;
  position: absolute;
  width: 70px;
  height: 35px;
  background-image: url("../assets/textures/tv/fanhui.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size:  70px 35px;
  cursor: pointer;
  z-index: 0; 
  pointer-events:all;
}
#fenxiang{
  top: 10px;
  right: 140px;
  position: absolute;
  width: 70px;
  height: 35px;
  background-image: url("../assets/textures/tv/fengxiang.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 70px 35px;
  cursor: pointer;
  z-index: 0; 
  pointer-events:all;
}
#shopping{
  display: none;
  bottom: 10px;
  right: 5px;
  position: absolute;
  width: 120px;
  height: 40px;
  background-image: url("../assets/textures/tv/shopping.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 120px 40px;
  cursor: pointer;
  z-index: 0; 
  pointer-events:all;
}

#jingying{
  display: none;
  top: 10px;
  left: 20px;
  position: absolute;
  width: 35px;
  height: 35px;
  background-image: url("../assets/textures/tv/jingying.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 35px 35px;
  cursor: pointer;
  z-index: 0; 
  pointer-events:all;
}
#shengying{
  
  top: 10px;
  left: 20px;
  position: absolute;
  width: 35px;
  height: 35px;
  background-image: url("../assets/textures/tv/shengying.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 35px 35px;
  cursor: pointer;
  z-index: 0; 
  pointer-events:all;
}
#jingying-main{
  display: none;
  top: 10px;
  left: 20px;
  position: absolute;
  width: 35px;
  height: 35px;
  background-image: url("../assets/textures/tv/jingying.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 35px 35px;
  cursor: pointer;
  z-index: 0; 
  pointer-events:all;
}
#shengying-main{

  top: 10px;
  left: 20px;
  position: absolute;
  width: 35px;
  height: 35px;
  background-image: url("../assets/textures/tv/shengying.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 35px 35px;
  cursor: pointer;
  z-index: 0; 
  pointer-events:all;
}
.wrap-P {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  /* z-index: 100000000; */
  display: none;
  /* pointer-events: none;
  user-select: none; */
}

.wrap-p>img {
  width: 0;
  height: 0;
  /* z-index: 1000000000; */
}

.wrap-p .exit {
  top: 0;
  right: 0;
  position: absolute;
  width: 50px;
  height: 50px;
  background-image: url('../assets/UI/brand_intro/common/exit.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px 20px;
  cursor: pointer;
  z-index: 0;
}
.wrap-p .exit2 {
  top: 2%;
  right: 2%;
  position: absolute;
  width: 84px;
  height: 42px;
  background-image: url('../assets/textures/back.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 84px 42px;
  cursor: pointer;
  z-index: 0;
}

.wrap-p .prev-img {
  /* left: 30px; */
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  /* width: 28px; */
  position: absolute;
  width: 42px;
  height: 86px;
  user-select: none;
  cursor: pointer;
  background: url('../assets/UI/brand_intro/common/prev-img.png');
  /* background-size: 28px 56px;
  background-repeat: no-repeat; */
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
}

.wrap-p .next-img {
  right:10px;
  top: 50%;
  transform: translateY(-50%);
  /* width: 28px; */
  position: absolute;
  width: 42px;
  height: 86px;
  user-select: none;
  cursor: pointer;
  background: url('../assets/UI/brand_intro/common/next-img.png');
  /* background-size: 28px 56px;
  background-repeat: no-repeat; */
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 0;
}

.wrap-p .tools {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  display: flex;
  justify-content: center;
  z-index: 0;
}

.wrap-p .tools .toHint {
  /* margin-right: 10px; */
  /* cursor: pointer; */
  position: absolute;
  bottom: 20px;
  width: 300px;
  height: 20px;
  /* line-height: 90px; */
  color: #ffffff;
  background: #88888800;
  text-align: center;
  font-size: 20px;
  user-select: none;
  z-index: 0;
}

.wrap-p .tools .tool {
  margin-right: 10px;
  cursor: pointer;
  width: 200px;
  height: 60px;
  line-height: 90px;
  color: #ffffff;
  background: #88888800;
  text-align: center;
  font-size: 30px;
  user-select: none;
  z-index: 0;
}

#boxclose {
  float: right;
  cursor: pointer;
  color: #fff;
  border: 0px solid #AEAEAE;
  border-radius: 3px;
  background: #222222;
  font-size: 31px;
  font-weight: bold;
  display: inline-block;
  line-height: 0px;
  /* padding: 11px 3px; */
  padding: 20px 20px; 
  position: absolute;
  right: 10px;
  top: 40px;
  z-index: 1002;
  opacity: 0.9;
}

.boxclose:before {
  content: "×";
}

#fade:hover ~ #boxclose {
  display:none;
}

.test:hover ~ .test2 {
  display: none;
}

#fadeout {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: black;
  transition: 0.3s;
  -webkit-transition: 1s;
  opacity: 1.0;
}